.loginDiv .md-form input[type="text"]:focus:not([readonly]),
.loginDiv .md-form input[type="password"]:focus:not([readonly]) {
	border-bottom: 1px solid #bbbbbb;
	box-shadow: 0 1px 0 0 #bbbbbb;
	color: #666666;
	text-align: left;
}

.loginDiv .md-form input[type="text"]:focus:not([readonly]) + label,
.loginDiv .md-form input[type="password"]:focus:not([readonly]) + label {
	color: #444444;
	font-size: 16px;
}

.loginDiv .md-form input[type="text"]:not([readonly]),
.loginDiv .md-form input[type="password"]:not([readonly]) {
	border-bottom: 1px solid #cccccc;
	box-shadow: 0 1px 0 0 #999999;
	color: #666666;
	text-align: left;
}

.loginDiv .md-form input[type="text"]:not([readonly]) + label,
.loginDiv .md-form input[type="password"]:not([readonly]) + label {
	color: #bbbbbb;
	font-size: 18px;
}

.loginDiv .md-form .prefix.active {
	color: #666666;
}
