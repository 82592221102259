.table-body {
	height: 500px;
	overflow: hidden;
	overflow-y: scroll;
}

.expensesGrid > div > div[class^="TableContainer-root"] {
	max-height: 500px;
}
/*thead[class^="MuiTableHead-root"]{
    display: table-header-group;
    position: sticky;
}*/

.expBtn {
	height: 38px;
	width: 38px;
	color: white;
	font-size: 0.65em;
	text-align: center;
	border-radius: 50%;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	line-height: 48px;
}

.exCancelBtn {
	background-color: red;
}

.exSaveBtn {
	background-color: #00c851;
}
