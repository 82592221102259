.dx-g-bs4-table-container {
	background-color: #696969;
}

.orderList .card-footer {
	background-color: #45526e;
}

.orderList .card-footer nav ul li,
.orderList .card-footer nav ul li .page-link {
	color: #ffffff;
}

.orderList table {
	background-color: #c0c0c0;
	color: #000;
}

.orderList [class^="TableContainer-root"] {
	background-color: #ffffff;
}

.orderList tfoot {
	display: none;
}

.orderList thead [class^="Title-title"] {
	overflow: hidden;
}

.orderList thead tr th {
	color: #ffffff;
	text-decoration-line: underline;
	font-size: 14px;
}

/*orderlist body alternating colors*/
.orderList tbody tr:nth-child(even) {
	background: #fffff0;
}
.orderList tbody tr:nth-child(odd) {
	background: #dddddd;
}

/*orderlist header colors*/
.orderList div:nth-child(1) {
	color: white;
	background-color: #778899;
}

/*orderlist grid spacer color*/
.orderList div div:nth-child(2) table thead tr th div:nth-child(2) div {
	background-color: white !important;
}

/*expenses grid pagination position*/
.expensesGrid div div:nth-child(2) {
	background-color: #778899;
	display: flex;
	justify-content: center;
	color: white;
}

/*expenses grid pagination color*/
.expensesGrid div div:nth-child(2) div span {
	color: white !important;
}

/*orderlist grouping buttons*/
.orderList div[role="button"] {
	background-color: #fffff0 !important;
	font-size: larger;
	color: black !important;
}

/*orderlist pagination position*/
.orderList div div:nth-child(3) {
	background-color: #778899;
	display: flex;
	justify-content: center;
	color: white;
}

/*orderlist pagination color*/
.orderList div div:nth-child(3) div span {
	color: white !important;
}

.orderList .btn {
	outline-color: #778899;
	color: #ffffff !important;
	border-color: #000000 !important;
}

.orderList thead {
	background-color: #778899;
	color: #ffffff;
}

.orderList table tbody tr td {
	color: #000;
	font-size: 14px;
	white-space: pre-wrap;
}

.orderList table tbody tr:hover {
	background-color: #778899;
	opacity: 0.7;
	color: #ffffff;
}

.dx-g-bs4-table-sticky {
	z-index: 0 !important;
}

.dx-g-bs4-paging-panel {
	display: flex;
	justify-content: center;
}

.dx-g-bs4-paging-panel nav {
	float: none !important;
}

.dmgSample {
	padding-top: 1px;
	border-top: 1px solid #dee2e6;
	z-index: 1000;
}

.cellOverride {
	border-top: 0 !important;
}

.note-tooltip {
	max-width: 30%;
	white-space: pre-wrap;
	z-index: 500000;
}

.filterDateOptions .md-form label {
	top: -8px;
	font-size: 13px;
}
