.dashboardText {
	color: #eeeeee;
	font-weight: 400;
}

.dashboardLine {
	border-color: #eeeeee;
}

.patientSearchDiv .md-form input[type="text"] {
	font-size: 26px;
}

.patientSearchDiv .md-form input[type="text"]:focus:not([readonly]) {
	border-bottom: 1px solid #ffffff;
	box-shadow: 0 1px 0 0 #dddddd;
}

.patientSearchDiv ::placeholder {
	color: #ffffff;
	opacity: 0.7;
}

.badgeLocation {
	position: absolute;
	right: -10px;
	top: -10px;
	font-size: 20px;
}

.searchDropdownDiv .btn-secondary.dropdown-toggle {
	background-color: #ff5722 !important;
	display: inline-block;
}

.w-90 {
	width: 90% !important;
}

.verticalPadding {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.preloader-wrapper {
	width: 8rem;
	height: 8rem;
}

.dbNoteHeader {
	color: white;
	display: flex;
	justify-content: space-between;
}

.dbNoteCompleteButton {
	float: right;
}

.dbStatusCards {
	width: 100%;
	height: 100%;
	padding: 1em;
}

.statusNew {
	background-color: #5881c1;
	margin: 20px;
	height: 250px;
}

.statusProcess {
	background-color: #6f95d1;
	margin: 20px;
	height: 250px;
}

.statusDelivery {
	background-color: #8aabe0;
	margin: 20px;
	height: 250px;
}

.dbStatusCardsText {
	color: white;
	font-size: 0.9em;
}

.dbStatusCardsText :hover {
	color: #ffe88b;
	text-decoration: underline;
}

.statusHost {
	text-align: center;
	cursor: pointer;
}

.reasonHost {
	text-align: right;
	font-weight: 500;
}

.reasonHostLink {
	cursor: pointer;
	margin-bottom: 4px;
}
