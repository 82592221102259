.tableHost > div > div[class^="TableContainer-root"] > table > tbody {
	height: 300px;
	overflow: hidden;
	overflow-y: scroll;
}

.ordersViewScroll {
	overflow-x: auto;
}

.orderReadyContainer {
	display: inline-block;
	max-width: 40%;
	margin-left: 1rem;
	margin-right: 1rem;
	vertical-align: middle;
}

.innerContainer {
	white-space: nowrap;
}

.openOrderCard {
	background-color: #f4511e;
	border: none;
	color: white;
	text-align: center;
	font-size: 15px;
	opacity: 0.8;
	transition: 0.3s;
	border-radius: 4px;
}
.openOrderCard:hover {
	opacity: 1;
}

.openDatePicker {
	display: inline-block;
	margin: 1px;
}

.orderNumber {
	margin: 1px;
	display: inline-block;
	text-align: center;
}

.orderSelect {
	display: inline-block;
	width: 20%;
	margin: 1%;
}

.tableHost div {
	color: white;
	background-color: #778899;
}

.tableHost thead tr th {
	color: #ffffff;
	text-decoration-line: underline;
	font-size: 14px;
}

.tableHost tbody tr:nth-child(even) {
	background: #fffff0;
}
.tableHost tbody tr:nth-child(odd) {
	background: #dddddd;
}

div[class^="ol-overlaycontainer-stopevent"] {
	position: absolute;
	padding-left: 15px;
	padding-top: 15px;
	z-index: 12;
}

div[class^="ol-rotate"] {
	display: none;
}

.ol-control button {
	height: 1.5em !important;
	width: 1.5em !important;
	border: solid 1px #ccc;
	font-size: 1.5em !important;
	background-color: #fff !important;
	color: #2d3c4d;
	cursor: pointer;
	border-radius: 4px;
	margin: 2px;
}

.ol-control button:focus {
	outline: unset;
}

.ol-popup {
	position: absolute;
	background-color: white;
	-webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
	filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
	padding: 15px;
	border-radius: 10px;
	border: 1px solid #cccccc;
	bottom: 55px;
	left: -50px;
	min-width: 310px;
	z-index: 1001;
	height: 100%;
	min-height: 170px;
	margin-left: -50px;
	margin-top: -200px;
}

.ol-popup:after,
.ol-popup:before {
	top: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.ol-popup:after {
	border-top-color: white;
	border-width: 10px;
	left: 48px;
	margin-left: -10px;
}

.ol-popup:before {
	border-top-color: #cccccc;
	border-width: 11px;
	left: 48px;
	margin-left: -11px;
}

.ol-popup-closer {
	text-decoration: none;
	position: absolute;
	top: 2px;
	right: 8px;
	background-color: transparent;
	border: 0;
}

.ol-popup-closer:after {
	content: "✖";
}

#popup-content p,
#filter-popup-content div {
	margin-top: 5px;
	margin-bottom: 5px;
}

.detailed {
	min-height: 290px;
	margin-top: -310px;
}

.address {
	font-family: Arial;
	display: inline-block;
	width: 300px;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: normal;
	overflow: hidden;
	font-size: 1.1em;
	line-height: 25px;
	letter-spacing: 0;
	color: #333333;
	/*cursor: pointer;*/
}

.coodinates {
	font-size: 1em;
	color: gray;
}
.edit-order-link,
.hide-feature-link {
	background-color: rgba(0, 0, 255, 0.75);
	color: white;
	border: 1px solid grey;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	padding: 6px 10px;
}

#searchBar {
	top: 40px;
	position: absolute;
	width: 50%;
	left: 25%;
	max-width: none;
	z-index: 100;
}

#geocoder {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	border-radius: 3px;
	background: #fff;
	width: 80%;
}

#geocoder i {
	width: 20px;
	height: 20px;
	background: url(https://samples.thinkgeo.com/cloud/example/image/seach.png);
	z-index: 10000;
	position: absolute;
	top: 10px;
	left: 10px;
}

#geocoder input {
	display: inline-block;
	font: 15px/20px sans-serif;
	margin: 0;
	color: rgba(0, 0, 0, 0.6);
	padding: 10px 35px;
	-webkit-appearance: none;
	border: 0px solid;
	width: 100%;
	border-radius: 3px;
	z-index: 10001;
}

#geocoder input:focus {
	outline: none;
}

.result-wrap {
	position: relative;
}

.loading {
	position: absolute;
	top: 17%;
	left: 45%;
}

#geocoderResult {
	list-style: none;
	padding: 0;
	margin: 0;
}

#geocoderResult li {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#geocoderResult li a {
	padding: 5px 15px;
	display: inline-block;
	width: 100%;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0;
	color: #3887be;
	cursor: pointer;
}

#geocoderResult li a:hover {
	color: #63b6e5;
}

.icon {
	position: absolute;
	bottom: -62px;
	left: 36px;
}

.hidden {
	display: none;
}

.focus {
	font-weight: 600;
}

div.ol-attribution {
	display: none;
}

#filterPopup {
	position: absolute;
	right: 0;
	top: 80px;
	margin-right: 30px;
	z-index: 10001;
	background-color: white;
	width: 25%;
	height: 50%;
	border-radius: 10px;
	border: 1px solid #cccccc;
	padding: 15px;
	bottom: 55px;
	min-width: 310px;
	min-height: 150px;
}

#map > div > canvas {
	z-index: 10;
}

.dx-datagrid .dx-datagrid-header-panel .dx-group-panel-item {
	background-color: #2991b7;
	color: white;
}

.dx-datagrid
	.dx-datagrid-header-panel
	.dx-group-panel-item
	.dx-column-indicators
	.dx-sort {
	color: white !important;
}

.dx-datagrid-header-panel {
	background-color: #33b5e5 !important;
}

.dx-datagrid-header-panel .dx-toolbar {
	background-color: #33b5e5 !important;
}

.dx-datagrid-header-panel .dx-group-panel-message {
	color: white !important;
}

/*grouped icon thing*/
.dx-datagrid-headers .dx-header-row {
	background-color: #2991b7;
	color: white;
}

/*grouped icon thing arrow*/
.dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
	color: white !important;
}

/*header text*/
.dx-datagrid-headers .dx-header-row .dx-column-indicators .dx-sort {
	color: white !important;
}

/*header*/
.dx-datagrid-headers .dx-header-row td:hover {
	background-color: #33b5e5 !important;
}

/*group panel background and text*/
.dx-datagrid-rowsview .dx-row.dx-group-row td {
	color: white !important;
	background-color: #33b5e5 !important;
}

/*white arrows for group panels*/
.dx-datagrid-rowsview .dx-row.dx-group-row td div {
	color: white !important;
}

.callPatientLink:link {
	color: black !important;
}

.callPatientLink:hover {
	color: blue !important;
}

.callPatientInput {
	overflow-y: scroll !important;
	text-align: left !important;
}

.answerIcon {
	color: #00c851;
}

.noAnswerIcon {
	color: red;
}

.mHeaderTitle h4 {
	width: 100%;
}

.rtsGrid tr:hover {
	background-color: #ccc;
}

.rtsGrid td {
	padding: 4px !important;
}

.delModal {
	max-width: 92vw !important;
}
