.userList {
	width: 250px;
	height: 500px;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 5%;
	padding: 0;
}

.unvisitedItem {
	background-color: #eee;
	color: black;
}

.userList li {
	display: block;
	padding: 2%;
	text-decoration: none;
}

.userList li:hover {
	background-color: #ccc;
}

.visitedItem {
	background-color: #0099cc;
	color: white;
}

.mainContainer {
	margin: auto;
}

.modalM {
	width: 100% !important;
}

.userMCols {
	padding: 0;
	margin: auto;
}

.userBtnDiv {
	margin: 0 50%;
}
