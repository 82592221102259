.activityHeader {
	text-align: end;
	position: absolute;
	right: 10px;
}

.individualNote {
	justify-content: space-between;
}

.notesHeaderRear {
	color: #ffffff;
	text-align: end;
	position: absolute;
	right: 1.5em;
}

.dropdown-menu {
	max-height: 250px;
	overflow-y: auto;
	overflow-x: hidden;
}
